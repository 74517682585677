var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('l-table',{ref:"refCatalogAttachmentTable",staticClass:"attachment-list",attrs:{"module-name":_vm.MODULE_NAME,"table-columns":_vm.tableColumns,"table-config-options":{
      endpoint: (_vm.MODULE_NAME + "/getActiveAttachment")
    },"is-editable":_vm.ACCESS_ABILITY_ATTACHMENTS},scopedSlots:_vm._u([{key:"cell(actions)",fn:function(ref){
    var data = ref.data;
return [_c('div',{staticClass:"text-nowrap mr-1",staticStyle:{"width":"40px"}},[_c('Can',{attrs:{"i":_vm.ACCESS_ABILITY_ATTACHMENTS.action,"a":_vm.ACCESS_ABILITY_ATTACHMENTS.subject}},[_c('feather-icon',{staticClass:"featherIcon cursor-pointer mr-1 border-dotted",attrs:{"icon":"LTrashIcon","size":"24"},on:{"click":function($event){return _vm.moveToTrash(data.item)}}}),_c('feather-icon',{staticClass:"featherIcon cursor-pointer mr-1 border-dotted",attrs:{"icon":"LEyeIcon","size":"24"},on:{"click":function($event){return _vm.showAttachment(data.item)}}})],1)],1)]}},{key:"cell(created_at)",fn:function(ref){
    var data = ref.data;
return [_vm._v(" "+_vm._s(new Date(data.value).toLocaleString('en-US', _vm.dateOptions))+" ")]}},{key:"filter",fn:function(){return [_c('Can',{attrs:{"i":_vm.ACCESS_ABILITY_ATTACHMENTS.action,"a":_vm.ACCESS_ABILITY_ATTACHMENTS.subject}},[_c('button-dashed',{on:{"click":function($event){return _vm.$router.push({ name: 'home-catalog-attachment-trash' })}}},[_c('feather-icon',{staticClass:"lightIcon",attrs:{"icon":"LTrashIcon","size":"16"}}),_vm._v(" "+_vm._s(_vm.$t('Trash'))+" ")],1),_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"LAddButtonIcon","size":"34"},on:{"click":_vm.triggerFileInput}})],1)]},proxy:true}])}),_c('l-file-upload',{ref:"refAttachmentFileUpload",attrs:{"is-editable":_vm.ACCESS_ABILITY_ATTACHMENTS,"accept-extension-array":['.jpg', '.jpeg', '.png', '.tif', '.pdf', '.bmp']},on:{"input":_vm.showModalToChoseCategory},model:{value:(_vm.chosenFiles),callback:function ($$v) {_vm.chosenFiles=$$v},expression:"chosenFiles"}}),_c('b-modal',{attrs:{"id":"attachment-create-modal","centered":"","hide-header":"","hide-footer":"","body-class":"l-modal__body","footer-class":"l-modal-footer","ok-title":"Upload","ok-variant":"success","ok-disabled":_vm.isOkUploadBtnActive,"cancel-disabled":_vm.isCancelUploadBtnActive,"cancel-variant":"outline-primary"},on:{"hidden":_vm.clearData,"ok":_vm.uploadFile}},[_c('b-overlay',{attrs:{"show":_vm.isUploading}},[_c('div',{staticClass:"l-modal__container p-2"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"l-modal__title",staticStyle:{"font-size":"24px"}},[_vm._v(" "+_vm._s(_vm.$t('How should this file be categorized?'))+" ")])]),_c('b-col',[_c('p',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$t('Please mind that you can only upload files up to 4MB each.'))+" ")]),_c('label',{staticClass:"ml-1"},[_vm._v("Category")]),_c('l-select',{attrs:{"name":"attachment_category_id ","field":{options: {transition: '',},
                       store:'attachmentCategoryListCatalog'}},model:{value:(_vm.sendData.attachment_category_id),callback:function ($$v) {_vm.$set(_vm.sendData, "attachment_category_id", $$v)},expression:"sendData.attachment_category_id"}}),(_vm.sendData.attachment_category_id && _vm.sendData.attachment_category_id.id === 0)?_c('l-select',{attrs:{"name":"purchaseorder ","field":{options: {transition: '',},
                       store:''}},model:{value:(_vm.sendData.attachment_purchase_link),callback:function ($$v) {_vm.$set(_vm.sendData, "attachment_purchase_link", $$v)},expression:"sendData.attachment_purchase_link"}}):_vm._e()],1)],1)],1)]),_c('b-row',{staticClass:"text-center"},[_c('b-col',{staticClass:"text-left",attrs:{"cols":"6"}},[_c('b-button',{staticClass:"font-medium-1 font-weight-bolder text-dark align-center mb-2 pl-3 pr-3 ml-2",attrs:{"variant":"outline-primary","disabled":_vm.isCancelUploadBtnActive},on:{"click":_vm.hideModalToChoseCategory}},[_c('feather-icon',{staticClass:"align-center",attrs:{"icon":"LCancelIcon","size":"16"}}),_vm._v(" "+_vm._s(_vm.$t('Cancel'))+" ")],1)],1),_c('b-col',{staticClass:"text-right",attrs:{"cols":"6"}},[_c('b-button',{staticClass:"font-medium-1 font-weight-bolder align-center mb-2 pl-3 pr-3 mr-2",attrs:{"variant":"success","disabled":_vm.isOkUploadBtnActive},on:{"click":_vm.uploadFile}},[_vm._v(_vm._s(_vm.$t('Upload'))+" ")])],1)],1)],1),_c('b-modal',{attrs:{"id":"attachment-move-to-trash-modal","centered":"","hide-header":"","body-class":"l-modal__body","footer-class":"l-modal-footer","ok-title":"Trash","ok-variant":"success","ok-disabled":_vm.isOkTrashBtnActive,"cancel-disabled":_vm.isCancelTrashBtnActive,"cancel-variant":"outline-primary"},on:{"ok":_vm.postMoveToTrash}},[_c('b-overlay',{attrs:{"show":_vm.isMovingToTrash}},[_c('div',{staticClass:"l-modal__container"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"l-modal__title"},[_vm._v(" Are you sure you want to send this attachment to the trash? ")])])],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }